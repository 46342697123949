var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(!_vm.isModal)?_c('b-row',{staticClass:"mt-1 mb-2"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"cols":"12","lg":"3"}},[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.sellers,"reduce":function (val) { return val.id; }},on:{"input":_vm.emitEventDateChange},model:{value:(_vm.host),callback:function ($$v) {_vm.host=$$v},expression:"host"}})],1)],1):_vm._e(),_c('b-row',{staticClass:"no-gutters"},[_c('l-calendar',{ref:"calendar",attrs:{"events":_vm.events,"date-location":"due_date","date-location-format":"YYYY-MM-DD","list-title-background-color":_vm.skin !== 'dark' ? '#f4f4f4' : ''},on:{"dateChange":_vm.fetchEvents},scopedSlots:_vm._u([{key:"date-header",fn:function(ref){
var date = ref.date;
var fullDay = ref.fullDay;
var haveEvents = ref.haveEvents;
var currentDate = ref.currentDate;
return [_c('div',{staticClass:"py-50 text-center w-100 border-bottom font-weight-bolder",class:currentDate
              ? ("bg-success text-white border-bottom-0 " + (_vm.skin !== 'dark' ? 'border-c4c4c4' : ''))
              : haveEvents
              ? ("bg-primary text-white border-bottom-0 " + (_vm.skin !== 'dark' ? 'border-c4c4c4' : ''))
              : ("" + (_vm.skin !== 'dark' ? 'border-c4c4c4' : ''))},[_vm._v(" "+_vm._s(fullDay)+", "+_vm._s(date)+" ")])]}},{key:"date",fn:function(ref){
              var events = ref.events;
              var haveEvents = ref.haveEvents;
return [_c('div',{staticClass:"w-100",class:haveEvents ? "text-white" : ''},[(haveEvents)?_c('task-calendar',{attrs:{"task":events[0],"type":'task'},on:{"emitEventDateChange":_vm.emitEventDateChange}}):_vm._e()],1)]}},{key:"date-footer",fn:function(ref){
        var haveEvents = ref.haveEvents;
        var events = ref.events;
        var numberOfEvents = ref.numberOfEvents;
        var fullMonth = ref.fullMonth;
        var year = ref.year;
        var day = ref.day;
return [(haveEvents)?_c('span',{staticClass:"px-50 pb-50 pt-0 text-right w-100",class:haveEvents ? "bg-light-primary" : ''},[_c('b-button',{staticClass:"rounded mr-50",staticStyle:{"padding":"4px","background":"#00aaaa !important","border-color":"#00aaaa !important"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.openModalEditEventShow(events[0])}}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon","size":"12"}})],1),(numberOfEvents > 1)?_c('b-button',{staticClass:"rounded",staticStyle:{"padding":"4px","background":"#ff7a00 !important","border-color":"#ff7a00 !important"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.openViewMoreEvents(events, fullMonth, year, day)}}},[_c('tabler-icon',{attrs:{"icon":"ListIcon","size":"12"}})],1):_vm._e()],1):_vm._e()]}},{key:"date-list",fn:function(ref){
        var event = ref.event;
return [_c('div',{staticClass:"w-100"},[_c('task-calendar',{staticClass:"w-100 cursor-pointer",attrs:{"task":event,"type":'task'},on:{"emitEventDateChange":_vm.emitEventDateChange},nativeOn:{"click":function($event){return _vm.openModalEditEventShow(event)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":_vm.isModal ? 'modal-task-edit-modal' : 'modal-task-edit',"title-class":"h2 text-white","ok-only":"","modal-class":"modal-primary","centered":"","size":"lg","title":"Tasks","hide-footer":""}},[_c('modal-task-edit',{attrs:{"only-read":true,"lead":_vm.lead,"task":_vm.selectedTask,"is-disabled":true}})],1),(_vm.viewMoreEventsController)?_c('view-more-events',{attrs:{"events":_vm.viewMoreEventsData.events,"month":_vm.viewMoreEventsData.month,"year":_vm.viewMoreEventsData.year,"day":_vm.viewMoreEventsData.day},on:{"close":_vm.closeViewMoreEvents},scopedSlots:_vm._u([{key:"event-place",fn:function(ref){
        var event = ref.event;
return [(event.id)?_c('task-calendar',{staticClass:"cursor-pointer",attrs:{"task":event,"type":'task'},on:{"emitEventDateChange":_vm.emitEventDateChange},nativeOn:{"click":function($event){return _vm.openModalEditEventShow(event)}}}):_vm._e()]}}],null,false,1912642765)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }